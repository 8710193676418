<template>
  <div class="container">
    <h1 class="site-title">Ardor</h1>
    <div class="input-container">
      <h3 class="input-heading">I want to feel...</h3>
      <input
        v-model="feeling"
        class="input-el"
        type="text"
        placeholder="Happy"
      />
      <button
        class="action-button"
        :class="{ isLoading: isLoading }"
        @click="submitMood"
      >
        <span class="animation"></span>
        <span class="animation"></span>
        <span class="animation"></span>

        10x my feelings
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      feeling: "",
      isLoading: false,
    };
  },
  computed: {
    validInput() {
      return this.feeling.length != 0;
    },
  },
  methods: {
    // MainPage.vue
    async submitMood() {
      if (this.validInput) {
        this.isLoading = true;
        await this.$store.dispatch("submitMood", this.feeling);
        this.isLoading = false;
        this.$router.push({
          name: "SecondPage",
        });
      } else {
        return null;
      }
    },
  },
};
</script>




<style scoped>
.container {
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  padding-top: 100px;
  height: 100vh;
}

.input-container {
  width: clamp(0px, 90%, 500px);
  margin: 0 auto;
  height: 200px;
  border-radius: 8px;
  border: solid 1px rgba(43, 44, 55, 1);
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  overflow: hidden;
}

.input-heading {
  text-align: left;
  padding: 10px 10px;
  border-bottom: solid 1px rgba(43, 44, 55, 1);
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.input-el {
  background: none;
  border: none;
  padding: 10px;
  font-size: 32px;
  color: white;
  width: 100%;
}

.input-el:focus {
  outline: none;
}

.action-button {
  border: none;
  display: block;
  width: 100%;
  margin-top: auto;
  background: rgb(76, 73, 156);
  padding: 10px;
  font-size: 16px;
  border-top: solid 1px rgba(43, 44, 55, 1);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.action-button .animation {
  position: absolute;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: rgb(76, 73, 156, 0.5);
  display: inline-block;
  z-index: -1;
  pointer-events: none;
}

.action-button:hover .animation:nth-of-type(1),
.action-button.isLoading .animation:nth-of-type(1) {
  animation: pulse 2s infinite;
}

.action-button:hover .animation:nth-of-type(2),
.action-button.isLoading .animation:nth-of-type(2) {
  animation: pulse 2s infinite;
  animation-delay: 0.5s;
}

.action-button:hover .animation:nth-of-type(3),
.action-button.isLoading .animation:nth-of-type(3) {
  animation: pulse 2s infinite;
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    scale: 0;
  }

  100% {
    scale: 30;
  }
}
</style>
