import { createRouter, createWebHistory } from 'vue-router';
import MainPage from './components/MainPage.vue';
import SecondPage from './components/SecondPage.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage', // The name is defined here
    component: MainPage
  },
  {
    path: '/second-page',
    name: 'SecondPage', // The name is defined here
    component: SecondPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
