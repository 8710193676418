<template>
  <div
    class="carousel__item movie-item"
    :style="{ backgroundImage: `url(${movie.poster_path})` }"
    @click="infoOpen = !infoOpen"
  >
    <!-- <div
                class="movie-content"
                :class="{ 'force-open': displayedMovie === index }"
              >
                <div class="movie-content-inner">
                  <h3 class="movie-heading">{{ movie.title }}</h3>
                  <div class="description">
                    <h5 class="movie-content-heading">Description</h5>
                    <p class="movie-description">{{ movie.overview }}</p>
                  </div>
                </div>
              </div> -->
    <div class="movie-backdrop"></div>
    <div class="alt-movie-content">
      <h3 class="alt-movie-heading">
        <span class="alt-heading-false">
          <span>
            {{ formatString(movie.title, 30, infoOpen) }}
          </span>
        </span>
        <span
          class="alt-movie-heading-transition-wrapper"
          :class="{ 'stop-transition': infoOpen }"
        >
          <span>
            {{ formatString(movie.title, 30, infoOpen) }}
          </span>
          <span>
            {{ formatString(movie.title, 30, infoOpen) }}
          </span>
        </span>
      </h3>
      <p :class="{ open: infoOpen }" class="alt-movie-description">
        {{ movie.overview }}
      </p>
    </div>
  </div>
</template>

<script>
import { formatString } from "../../utils/utils";

export default {
  props: {
    movie: Object,
  },
  components: {},
  data() {
    return {
      infoOpen: false,
    };
  },
  methods: {
    formatString,
  },
};
</script>

<style>
.movie-item {
  background-size: cover;
  background-position: 50% 0%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.movie-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(1.5) translateY(100%);
  transition: transform 0.5s;
  overflow: hidden;
  background: rgba(43, 44, 55, 0.99);
}

.movie-content-inner {
  transition: transform 0.5s;
  transform: translateY(-100%);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.movie-content.force-open {
  transform: none !important;
  opacity: 1;
  visibility: visible;
}

.movie-content.force-open .movie-content-inner {
  transform: none;
}

.movie-item:hover .movie-content {
  visibility: visible;
  transform: scale(1) translateY(0%);
  opacity: 1;
  visibility: visible;
}

.movie-item:hover .movie-content-inner {
  transform: translateY(0%);
}

.movie-heading {
  text-align: left;
  margin: 0;
  font-size: 24px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.movie-content .description {
  order: -1;
  padding: 20px;
}

.movie-content-heading {
  font-size: 16px;
  text-align: left;
  margin: 0;
}

.movie-description {
  text-align: left;
  margin-top: 0;
  font-size: 14px;
  margin-top: 5px;
}
.alt-movie-content {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(43, 44, 55, 1);
  padding: 14px 10px;
  transition: height 1s;
}

.alt-movie-heading {
  text-align: left;
  font-size: 16px;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.alt-movie-description {
  text-align: left;
  font-size: 14px;
  height: 0;
  visibility: hidden;
  margin: 0;
  transition: height 0.5s;
}

.alt-movie-description.open {
  height: auto;
  visibility: visible;
  margin: 14px 0px;
}

.site-button.info-button {
  display: block;
  height: 0;
  margin: 0;
  visibility: hidden;
}

.movie-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.5;
  visibility: hidden;
}
/* .movie-item:hover .movie-backdrop {
  visibility: visible;
} */
.movie-item:hover .info-button {
  margin-bottom: 14px;
  visibility: visible;
  height: auto;
}

.stop-transition {
  transition: none !important;
}

.movie-item:hover .alt-movie-heading-transition-wrapper:not(.stop-transition) {
  transform: translateY(-100%);
}

.alt-heading-false {
  visibility: hidden;
}

.alt-movie-heading-transition-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s cubic-bezier(0, 0.01, 0, 0.97);
}
</style>