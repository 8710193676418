export function formatPlaylistName(name) {
  if (name.length > 20) {
    return name.slice(0, 20) + "...";
  } else {
    return name;
  }
}

export function formatActivityDescription(desc) {
  let sliceEnd = desc.indexOf("', 'image_keyword'");
  return desc.slice(0, sliceEnd);
}

export function formatString(string, bound, override) {
  if (override || string.length <= bound) {
    return string;
  } else {
    return string.slice(0, bound) + "...";
  }
}
