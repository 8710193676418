<template>
  <label
    :for="'activity-' + index"
    class="carousel__item activity-item"
    :style="{ backgroundImage: `url(${activity.image_url})` }"
    @click="descOpen = !descOpen"
  >
    <div class="content">
      <h3 class="activity-heading">{{ activity.activity }}</h3>
      <p class="activity-description" :class="{ open: descOpen }">
        {{ formatActivityDescription(activity.description) }}
      </p>
      <!-- <label :for="'activity-' + index" class="checkbox">
        <input
          class="activity-check"
          :id="'activity-' + index"
          type="checkbox"
        />
        <span class="check-box-wrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-check"
          >
            <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        </span>
      </label> -->
    </div>
  </label>
</template>

<script>
import { formatActivityDescription } from "../../utils/utils";

export default {
  props: {
    activity: Object,
    index: Number,
  },
  data() {
    return {
      descOpen: false,
    };
  },
  methods: {
    formatActivityDescription,
  },
};
</script>

<style scoped>
.activity-item {
  padding: 0;
  display: block;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-size: cover;
}
.activity-item .content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(43, 44, 55, 1);
}

.activity-heading {
  text-align: left;
  width: 100%;
  margin: 0;
}

.activity-description {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  font-size: 14px;
  margin: 0;
  height: 0;
  visibility: hidden;
}

.activity-description.open {
  margin-top: 10px;
  height: auto;
  visibility: visible;
}

.selected {
  border: 1px solid rgb(76, 73, 156);
}

.activity-heading {
  font-size: 16px;
}

/* .checkbox {
  position: absolute;
  bottom: 0;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  aspect-ratio: 1/1;
  cursor: pointer;
  align-self: end;
}

.check-box-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(32, 33, 44, 1);
  border-radius: 4px;
  border: rgba(130, 143, 163, 1) solid 1px;
} */
/* 
.activity-check {
  width: 0;
  height: 0;
  position: absolute;
  visibility: hidden;
}

.feather-check {
  width: 20px;
  height: 20px;
  visibility: hidden;
}

.activity-check:checked + .check-box-wrap {
  background: rgb(76, 73, 156) !important;
  border: none;
}

.activity-check:checked + .check-box-wrap .feather-check {
  visibility: visible;
} */
</style>