<template>
  <div class="second-page">
    <h1 class="site-heading">
      <span class="site-heading-wrapper"> Suggestions </span>
    </h1>
    <div class="site-body">
      <div class="mood-section" v-if="serverData?.playlists.length">
        <h2 class="section-heading">Playlists</h2>
        <Carousel navigationEnabled mouseDrag>
          <Slide
            class="music-slide"
            v-for="(playlist, index) in serverData.playlists"
            :key="index"
          >
            <a
              :href="playlist.url"
              target="_blank"
              class="carousel__item music-item playlist-link"
            >
              <div
                class="playlist-image"
                :style="{ backgroundImage: `url(${playlist.thumbnail})` }"
              >
                <span class="link-icon-transition">
                  <img
                    class="arrow-icon false"
                    src="../assets/arrow-up.svg"
                    alt=""
                  />
                  <span class="transition-wrapper">
                    <img
                      class="arrow-icon"
                      src="../assets/arrow-up.svg"
                      alt=""
                    />
                    <img
                      class="arrow-icon"
                      src="../assets/arrow-up.svg"
                      alt=""
                    />
                  </span>
                </span>
              </div>
              <div class="row">
                <h3 class="playlist-name">
                  <span class="false-playlist-name">
                    {{ formatPlaylistName(playlist.name) }}
                  </span>
                  <span class="name-transition-wrapper">
                    <span class="playlist-name-word">
                      {{ formatPlaylistName(playlist.name) }}
                    </span>

                    <span class="playlist-name-word">
                      {{ formatPlaylistName(playlist.name) }}
                    </span>
                  </span>
                </h3>
              </div>
            </a>
          </Slide>

          <template #addons>
            <navigation />
          </template>
        </Carousel>
      </div>
      <div class="mood-section" v-if="serverData?.movies.length">
        <h2 class="section-heading">Movies</h2>
        <Carousel>
          <Slide
            class="movie-slide"
            v-for="(movie, index) in serverData.movies"
            :key="index"
          >
            <movie-card :movie="movie" />
          </Slide>
        </Carousel>
      </div>
      <div class="mood-section" v-if="validActivities.length">
        <h2 class="section-heading">Activities</h2>
        <Carousel navigationEnabled :itemsToShow="1">
          <Slide
            class="activity-slide"
            v-for="(activity, index) in validActivities"
            :key="index"
          >
            <activity-card :activity="activity" :index="index" />
          </Slide>

          <template #addons>
            <navigation />
          </template>
        </Carousel>
      </div>
      <div class="no-valid" v-else>
        <p>No valid activities found 😢</p>
      </div>

      <button class="again-button" @click="$router.push('/')">
        <span class="animation"></span>
        <span class="animation"></span>
        <span class="animation"></span>

        10x My Feelings Again
      </button>
    </div>
  </div>
</template>
  
  <script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import { mapState } from "vuex";

import { formatPlaylistName } from "../utils/utils";

import MovieCard from "./shared/MovieCard.vue";
import ActivityCard from "./shared/ActivityCard.vue";

export default {
  name: "SecondPage",
  components: {
    Carousel,
    Slide,
    Navigation,
    MovieCard,
    ActivityCard,
  },
  data() {
    return {
      breakpoints: {
        700: {},
      },
      displayedMovie: null,
    };
  },
  computed: {
    validActivities() {
      if (!this.serverData || !this.serverData.activities) {
        return [];
      }

      return this.serverData.activities.filter(
        (activity) => activity.activity !== "Activity not found"
      );
    },

    ...mapState({
      serverData: (state) => state.response,
    }),
  },
  methods: {
    formatPlaylistName,
  },

  mounted() {},
};
</script>
  
<style>
.second-page {
  padding: 32px;
}
.site-heading {
  margin: 0;
  border-bottom: 1px #3e3f4e solid;
  position: fixed;
  left: 0;
  right: 0;
  padding: 20px;
  top: 0;
  background: #000;
  z-index: 100;
}

.site-heading-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
}

.site-body {
  margin-top: 100px;
  max-width: 1200px;
  margin: 100px auto;
}

.mood-section {
  margin-top: 40px;
}

.no-valid {
  background: #2b2c37;
  padding: 10px;
  border-radius: 8px;
  margin-top: 30px;
  font-size: 18px;
}

.carousel__track {
  display: flex;
  gap: 10px;
}

.carousel__slide {
  /* height: 100%; */
}

.movie-slide {
  flex-basis: clamp(32%, 300px, 100%);
  height: 400px;
  cursor: pointer;
  flex-grow: 1;
}

.music-slide {
  flex-basis: 200px;
}

.carousel__item {
  /* aspect-ratio: 400/440; */
  /* height: 440px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  background-color: #2b2c37;
  border-radius: 10px;
}

.carousel__prev,
.carousel__next {
  /* background: white; */
  border: solid 2px white;
  border-radius: 100%;
}

.carousel__icon {
  fill: white;
}

.section-heading {
  margin: 0;
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 600;
}

.music-item {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  padding: 0;
  overflow: hidden;
  width: 200px;
  position: relative;
  /* height: 200px; */
}

.playlist-link {
  text-decoration: none;
  color: white;
}

.music-item .row {
  padding: 0px 10px;
}

.playlist-image {
  aspect-ratio: 1/1;
  background-size: cover;
  width: 100%;
  position: relative;
}

.playlist-name {
  text-align: left;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

.arrow-icon {
  width: 20px;
  stroke: white;
}

.arrow-icon.false {
  visibility: hidden;
}

.link-icon-transition {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  overflow: hidden;
  position: absolute;
  bottom: 10px;
  right: 10px;
  /* mix-blend-mode: difference; */
  background: #2b2c37;
  border-radius: 4px;
}

.transition-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.5s cubic-bezier(0, 0.01, 0, 0.97);
  transform: rotate(45deg);
}

.music-item:hover .transition-wrapper {
  transform: translateY(-14px) translateX(14px) rotate(45deg);
}

.false-playlist-name {
  visibility: hidden;
}

.name-transition-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s cubic-bezier(0, 0.01, 0, 0.97);
}

.music-item:hover .name-transition-wrapper {
  transform: translateY(-50%);
}

.activity-slide {
  flex-basis: clamp(100px, 300px, 80%);
  aspect-ratio: 3/2;
  /* height: clamp(fit-content, ); */
}

.again-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: 200px;
  background: rgb(76, 73, 156);
  font-size: 20px;
  font-weight: 500;
  color: white;
  height: 200px;
  padding: 0;
  border-radius: 100%;
  margin: 150px auto;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-left: auto;
  align-self: end;
}

.again-button .animation {
  position: absolute;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background: rgb(76, 73, 156, 0.5);
  display: inline-block;
  z-index: -1;
}

.again-button:hover .animation:nth-of-type(1) {
  animation: pulse 5s infinite;
}

.again-button:hover .animation:nth-of-type(2) {
  animation: pulse 5s infinite;
  animation-delay: 0.5s;
}

.again-button:hover .animation:nth-of-type(3) {
  animation: pulse 5s infinite;
  animation-delay: 1s;
}

@keyframes pulse {
  0% {
    scale: 0;
  }

  100% {
    scale: 20;
  }
}
</style>
  