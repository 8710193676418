<template>
  <router-view />
</template>

<script>
export default {
  data() {
    return {};
  },

  computed() {},
};
</script>

<style>
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}
#app {
  /* background: #000112; */
  background: #000;
  min-height: 100vh;
  width: 100vw;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif;
}
</style>
