import { createStore } from "vuex";

import axios from "axios";

export default createStore({
  state: {
    response: null,
  },
  getters: {},
  mutations: {
    storeResponse(state, data) {
      state.response = JSON.parse(data);
    },
  },
  actions: {
    async submitMood({ commit }, feeling) {
      try {
        await axios
          .post("https://mcaas.pythonanywhere.com/", {
            mood: feeling,
          })
          .then((res) => {
            const unwrapRes = JSON.stringify(res.data);
            commit("storeResponse", unwrapRes);
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  modules: {},
});
